import styled from "styled-components";

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

export const ModalContent = styled.div`
  background: #1e1e1e;
  min-width: 240px;
  border-radius: 30px;
  padding: 20px 30px;
  position: relative;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  border: 3px solid #81c2ff;
  z-index: 1000;

  @media (min-width: 400px) {
    padding: 20px 50px;
  }
`;

export const ModalTitleContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-bottom: 30px;

  position: relative;
  border-bottom: 2px solid #81c2ff;

  @media (min-width: 400px) {
    margin-top: 30px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;

  width: 40px;
  height: 40px;

  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #2b74b9;
  font-size: 20px;
  color: white;
  cursor: pointer;

  @media (min-width: 1000px) {
    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 10px;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 5px;

  @media (min-width: 400px) {
    margin-bottom: 10px;
  }
`;

export const StyledInput = styled.input`
  padding: 10px 20px;
  border: 2px solid #81c2ff;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  background: #555556;
  color: white;

  text-align: start;
  font-family: "Fira Sans", sans-serif;

  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  &:focus {
    outline: none;
    border-color: #81c2ff;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const HalfWidthWrapper = styled(InputWrapper)`
  width: 100%;
  @media (min-width: 1000px) {
    width: 48%;
  }
`;

export const StyledTextarea = styled.textarea`
  padding: 10px 20px;
  border: 2px solid #81c2ff;
  border-radius: 10px;
  width: 100%;
  min-width: 100%;
  min-height: 100px;
  box-sizing: border-box;
  background: #555556;
  color: white;

  &:focus {
    outline: none;
    border-color: #81c2ff;
  }
`;

export const ValidationIcon = styled.img<{
  isValid: boolean;
  textarea?: boolean;
}>`
  width: ${(props) => !props.isValid && "16px"};
  height: ${(props) => !props.isValid && "16px"};
  position: absolute;
  top: 50%;
  right: ${(props) => (props.isValid ? "-5px" : "10px")};
  top: ${(props) => (props.textarea ? "20px" : "50%")};
  transform: translate(-50%, -50%);
  color: ${({ isValid }) => (isValid ? "green" : "red")};

  @media (min-width: 600px) {
    right: ${(props) => (props.isValid ? "-10px" : "5px")};
  }
`;

export const SubmittedFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 10px;
  margin-bottom: 30px;
`;

export const ButtonContainer = styled.div`
  position: relative;
  padding: 20px 10px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 400px) {
    padding: 20px 10px;
    padding-top: 30px;
  }

  @media (max-width: 357px) {
    padding-top: 40px;
  }
`;

export const AllFieldsAreRequiredContainer = styled.div`
  position: absolute;
  top: -10px;
  left: 0%;
`;
