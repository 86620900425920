import styled from "styled-components";

const FONT_WHITE = "#ffffff";

export const H1 = styled.h1`
  font-family: "Manrope", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 62.4px */

  color: ${FONT_WHITE};
`;

export const H2 = styled.h2`
  font-family: "Fira Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: ${FONT_WHITE};
`;

export const H3 = styled.h3`
  font-family: "Fira Sans", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: ${FONT_WHITE};
  font-feature-settings: "clig" off, "liga" off;
`;

export const H4 = styled.h4`
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 33.6px */

  color: ${FONT_WHITE};
  font-feature-settings: "clig" off, "liga" off;
`;

export const H5 = styled.h5`
  font-family: "Fira Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: ${FONT_WHITE};
`;

export const H6 = styled.h6`
  font-family: "Fira Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */

  color: ${FONT_WHITE};
`;

export const AppContainer = styled.div`
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  padding-bottom: 50px;

  align-items: center;
`;

interface ButtonProps {
  empty?: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  padding: 20px 30px;
  align-items: flex-end;
  align-content: flex-end;
  gap: 10px;
  flex-wrap: wrap;
  border-radius: 85px;
  background: #2b74b9;
  cursor: pointer;

  font-family: "Fira Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;

  border: none;

  color: ${FONT_WHITE};

  @media (max-width: 330px) {
    padding: 20px 20px;
  }

  @media (min-width: 1000px) {
    &:hover {
      transform: ${(props) => !props.disabled && "scale(1.05)"};
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: grey;
  }
`;

export const ColoredSpan = styled.span`
  color: #81c2ff;
`;
