import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AppContainer } from "./App.style";
import Navbar from "./features/navbar/Navbar";
import Loading from "./features/loading/Loading";
import Homepage from "./features/home/Homepage";
import Realizations from "./features/realizacje/Realizations";
import Footer from "./features/footer/Footer";

function App() {
  const [loading, setLoading] = useState<boolean>(true);

  const handleStartLoading = () => setLoading(true);

  const handleStopLoading = () => {
    window.scrollTo({ top: 0 });
    setLoading(false);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2seconds

    return () => clearTimeout(timerId); // Clear the timeout if the component is unmounted
  }, []);

  return (
    <BrowserRouter>
      <AppContainer>
        {loading && <Loading />}
        <Navbar startLoading={handleStartLoading} />

        <Routes>
          <Route path="*" element={<Navigate replace to="/" />} />
          <Route
            path="/"
            element={
              <Homepage loading={loading} stopLoading={handleStopLoading} />
            }
          />
          <Route
            path="/realizacje"
            element={
              <Realizations loading={loading} stopLoading={handleStopLoading} />
            }
          />
        </Routes>

        <Footer startLoading={handleStartLoading} />
      </AppContainer>
    </BrowserRouter>
  );
}

export default App;
