import React from "react";
import {
  AboutContainer,
  AboutContentContainer,
  AboutImage,
  AboutImageContainer,
  AboutTitleContainer,
  BoldSpan,
  ButtonContainer,
  CompanyNameContainer,
  TextContainer,
} from "./About.style";
import { Button, ColoredSpan, H1, H5, H6 } from "../../../../App.style";
import contactBmw from "../../../../assets/about/about-bmw.png";
import { useTranslation } from "react-i18next";

interface AboutProps {
  openContactModal: () => void;
}

const About: React.FC<AboutProps> = ({ openContactModal }) => {
  const { t } = useTranslation();
  return (
    <AboutContainer>
      <AboutContentContainer>
        <AboutTitleContainer>
          <H1>{t("about.title")}</H1>
        </AboutTitleContainer>
        <CompanyNameContainer>
          <H5>
            <ColoredSpan>M-Car technology</ColoredSpan>
          </H5>
        </CompanyNameContainer>
        <TextContainer>
          <H6 style={{ marginBottom: 30 }}>
            {t("about.text1")}
            <BoldSpan>{t("about.text1bold")}</BoldSpan>.
          </H6>
          <H6>
            {t("about.text2")}
            <BoldSpan>{t("about.text2bold")}</BoldSpan>{" "}
            {t("about.text2continue")}
          </H6>
        </TextContainer>

        <ButtonContainer>
          <Button onClick={openContactModal}>{t("about.buttonText")}</Button>
        </ButtonContainer>
      </AboutContentContainer>
      <AboutImageContainer>
        <AboutImage src={contactBmw} />
      </AboutImageContainer>
    </AboutContainer>
  );
};

export default About;
