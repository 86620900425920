import React, { useEffect, useRef, useState } from "react";
import {
  HomeContainer,
  LandingSection,
  LandingVideoContainer,
  StyledPoster,
  StyledVideo,
} from "./Home.style";
import Section from "./Section";
import About from "./sections/about/About";
import Contact from "./sections/contact/Contact";
import Assistance from "./sections/assistance/Assistance";
import Offers from "./sections/offers/Offerss";
import landingPoster from "../../assets/landing/poster.png";
import landingVideoNew from "../../assets/landing/bmw-video-landing.mp4";
import ContactFormModal from "./ContactFormModal";

interface HomepageProps {
  loading: boolean;
  stopLoading: () => void;
}

const Homepage: React.FC<HomepageProps> = ({ loading, stopLoading }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [poster, setPoster] = useState<boolean>(false);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        setPoster(true);
        console.error("Video play failed", error);
      });
    }
  }, [loading]);

  const [isContactFormModalVisible, setIsContactFormModalVisible] =
    useState<boolean>(false);

  const handleOpenContactModal = () => setIsContactFormModalVisible(true);
  const handleCloseContactModal = () => setIsContactFormModalVisible(false);

  return (
    <HomeContainer>
      <ContactFormModal
        isOpen={isContactFormModalVisible}
        onClose={handleCloseContactModal}
      />
      <LandingSection>
        <LandingVideoContainer>
          {poster ? (
            <StyledPoster src={landingPoster} />
          ) : (
            <StyledVideo
              ref={videoRef}
              autoPlay
              muted
              playsInline
              onLoadedData={stopLoading}
              poster={landingPoster}
            >
              <source src={landingVideoNew} type="video/mp4" />
              Your browser does not support the video tag.
            </StyledVideo>
          )}
        </LandingVideoContainer>
      </LandingSection>

      <Section id={"about"}>
        <About openContactModal={handleOpenContactModal} />
      </Section>

      <Section id={"offers"}>
        <Offers />
      </Section>

      <Section id={"contact"}>
        <Contact />
      </Section>

      <Section id={"assistance"}>
        <Assistance />
      </Section>
    </HomeContainer>
  );
};

export default Homepage;
