import React, { useCallback, useEffect, useState } from "react";
import { H1, Button, H4 } from "../../App.style";
import {
  RealizationSectionContainer,
  RealizationSectionTitleContainer,
  RealizationSectionContentContainer,
  RealizationSectionCardContainer,
  RealizationSectionButtonContainer,
  RealizationImage,
} from "./Realizations.style";
import ImageSlider from "./ImageSlider";
import { useTranslation } from "react-i18next";

interface RealizationComponentProps {
  title: string;
  description: string;
  images: string[];
  sectionId: string;
}

const RealizationComponent: React.FC<RealizationComponentProps> = ({
  title,
  description,
  images,
  sectionId,
}) => {
  const [showAll, setShowAll] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleOfferButonClick = () => {
    if (showAll) {
      handleScroll(sectionId);
    } else {
      setShowAll((prev) => !prev);
    }
  };

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.pageYOffset - 0,
        behavior: "smooth",
      });

      setTimeout(() => {
        setShowAll(false);
      }, 100);
    }
  };

  //slider

  const [showSlider, setShowSlider] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = (index: number) => {
    setSelectedIndex(index);
    setShowSlider(true);
  };

  const handleCloseSlider = () => {
    setShowSlider(false);
  };

  const { t } = useTranslation();

  return (
    <RealizationSectionContainer $grey={Number(sectionId) % 2 === 0}>
      {showSlider && (
        <ImageSlider
          images={images}
          initialIndex={selectedIndex}
          onClose={handleCloseSlider}
        />
      )}
      <RealizationSectionTitleContainer>
        <H1>{title}</H1>
        <H4>{description}</H4>
      </RealizationSectionTitleContainer>
      <RealizationSectionContentContainer>
        {images
          .slice(0, isMobile && !showAll ? 3 : images.length)
          .map((image, index) => {
            return (
              <RealizationSectionCardContainer
                $shouldDisplay={index < 3 || (isMobile && showAll) || !isMobile}
                key={`RealizationSection-card-${index}`}
              >
                <RealizationImage
                  src={image}
                  onClick={() => handleClick(index)}
                  loading="lazy"
                  alt={`Realization ${sectionId} image ${index + 1}`}
                />
              </RealizationSectionCardContainer>
            );
          })}
      </RealizationSectionContentContainer>
      {isMobile && (
        <RealizationSectionButtonContainer>
          <Button onClick={handleOfferButonClick}>
            {showAll ? t("realizations.showLess") : t("realizations.showMore")}
          </Button>
        </RealizationSectionButtonContainer>
      )}
    </RealizationSectionContainer>
  );
};

export default React.memo(RealizationComponent);
