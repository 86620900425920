import styled from "styled-components";

export const SelectorContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

export const SelectedOption = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 35px;

  border-radius: 5px;

  background: black;

  z-index: 1;
`;

export const Optionn = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background-color: grey;
  }
`;

export const OptionSelected = styled.div`
  position: absolute;
  height: 1px;
  background: white;
  width: 90%;
  bottom: 3px;
`;

export const CountryText = styled.div`
  width: 15px;
`;

export const FlagImg = styled.img`
  width: 15px;
  height: 10px;
`;

export const FlagIcon = styled.div`
  width: 20px;
  height: auto;
  margin-right: 5px;
`;
