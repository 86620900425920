import React, { useEffect, useState } from "react";
import {
  FooterContainer,
  FooterContent,
  FooterH6,
  FooterLogo,
  FooterLogoContainer,
} from "./Footer.style";
import { H5 } from "../../App.style";
import logo from "../../assets/navbar/logo.svg";

import { StyledLink } from "../home/sections/contact/Contact.style";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

interface FooterProps {
  startLoading: () => void;
}

const Footer: React.FC<FooterProps> = ({ startLoading }) => {
  const [scrollTarget, setScrollTarget] = useState<string>();
  const location = useLocation();

  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScroll = (id: string) => {
    if (location.pathname !== "/") {
      navigate("/");
      setScrollTarget(id); // Set the scroll target for useEffect to use later
    } else {
      scrollToTarget(id);
    }
  };

  const scrollToTarget = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.pageYOffset - 120,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (location.pathname === "/" && scrollTarget) {
      startLoading();
      // Introduce a delay before attempting to scroll
      const timeoutId = setTimeout(() => {
        scrollToTarget(scrollTarget);
        setScrollTarget(undefined); // Reset the scroll target
      }, 400); // Adjust the delay duration as needed

      // Cleanup timeout on component unmount or if conditions change
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, scrollTarget]);

  const { t } = useTranslation();
  return (
    <FooterContainer>
      <FooterLogoContainer onClick={handleLogoClick}>
        <FooterLogo src={logo} />
        <H5>M-Car Technology</H5>
      </FooterLogoContainer>
      <FooterContent>
        <H5>M-Car</H5>
        <FooterH6 onClick={() => handleScroll("about")}>
          {t("navbar.about")}
        </FooterH6>
        <FooterH6 onClick={() => handleScroll("offers")}>
          {t("navbar.offer")}
        </FooterH6>
        <FooterH6 onClick={() => handleScroll("contact")}>
          {t("navbar.contact")}
        </FooterH6>
      </FooterContent>
      <FooterContent>
        <H5>{t("navbar.contact")}</H5>
        <StyledLink href="mailto:mcartechnologycieszyn@gmail.com">
          <FooterH6>mcartechnologycieszyn@gmail.com</FooterH6>
        </StyledLink>
        <FooterH6>
          <StyledLink href="tel:+48698899266">+48 698 899 266</StyledLink>
        </FooterH6>
        <FooterH6>
          <StyledLink
            href="https://www.google.com/maps/place/M-CAR+technology/@49.7515488,18.6473212,17z/data=!3m1!4b1!4m6!3m5!1s0x47140353ffcc492b:0xe102712bb951af0b!8m2!3d49.7515488!4d18.6498961!16s%2Fg%2F11ff1_742l?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
          >
            Zamarski, {t("contact.street")}. Główna 61
          </StyledLink>
        </FooterH6>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
