import { translationsPl } from "./pl";

export const translationsEn: typeof translationsPl = {
  navbar: {
    about: "About",
    offer: "Offer",
    realizations: "Realizations",
    contact: "Contact",
  },

  about: {
    title: "About Us",
    text1:
      "We are a trade and service company specializing in a wide range of services related to ",
    text1bold: "BMW brand cars",
    text2: "Our mission is to deliver ",
    text2bold: "the highest quality services,",
    text2continue: " which will satisfy even the most demanding customers.",
    buttonText: "Contact form",
  },

  contactForm: {
    title1: "Contact ",
    title2: "Form",
    submittedText:
      "Thank you for sending the message. We will review your submission and contact you as soon as possible.",
    inputs: {
      name: "Name",
      phone: "Phone Number",
      email: "Email",
      vin: "VIN",
      description: "Fault Description",
    },
    errorMessage: "*All fields must be filled out",
    buttonText: "Submit Form",
    buttonSent: "Sending...",
  },

  offers: {
    title: "What do we offer?",
    cards: {
      card1: "Coding, programming, and computer diagnostics",
      card2: "Pre-purchase on-site computer inspection",
      card3: "Customization and activation of additional features",
      card4: "Software and driver updates",
      card5: "Electronic tuning",
      card6: "Troubleshooting Adblue, SCR, EGR, DPF, FAP systems",
      card7: "Removing power restrictions",
      card8: "Support for mechanical workshops",
      card9: "Mechanical service with server entry",
      card10: "Electromechanics",
    },
    showMore: "See More",
    showLess: "Show Less",
  },

  contact: {
    title: "Contact",
    mondayToFriday: "mon-fri",
    saturday: "sat",
    street: "St",
    findUs1: "Find us",
    findUs2: "here",
  },

  assistance: {
    title1: "Need",
    title2: "roadside assistance",
    or: "or",
    polish: "Polish",
    czech: "Czech",
    english: "English",
  },

  realizations: {
    title: "Projects",
    text1: "See how we restore cars",
    text2: "to",
    text3: "full functionality",
    showLess: "Show less",
    showMore: "See more",
  },
};
