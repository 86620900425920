import styled from "styled-components";
import { H6 } from "../../App.style";

export const FooterContainer = styled.footer`
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 20px;

  max-width: 1200px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 0px;
  }
`;

export const FooterLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    width: 40%;
    margin-bottom: 0px;
  }
`;

export const FooterContent = styled.div`
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media (min-width: 768px) {
    width: 30%;
    margin-bottom: 0px;
  }
`;

export const FooterLogo = styled.img`
  width: 100px;
  height: 50px;
`;

export const FooterH6 = styled(H6)`
  opacity: 0.6;

  cursor: pointer;
`;
