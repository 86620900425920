import styled, { css, keyframes } from "styled-components";

export const OffersContainer = styled.div`
  width: 100%;

  background: #050807;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    gap: 25px;
  }
`;

export const OffersTitleContainer = styled.div`
  margin-bottom: 30px;
`;

export const OffersContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  max-width: 400px;

  @media (min-width: 768px) {
    max-width: 1400px;
  }
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const OffersCardContainer = styled.div<{ $shouldDisplay: boolean }>`
  width: 270px;
  height: 150px;

  margin: 10px;
  border: 1px solid orange;

  display: flex;

  border: 1px solid #81c2ff;
  border-radius: 32px;

  position: relative;
  overflow: hidden;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  visibility: ${(props) => (props.$shouldDisplay ? "visible" : "hidden")};
  animation: ${(props) =>
    props.$shouldDisplay
      ? css`
          ${fadeInUp} 0.5s forwards
        `
      : "none"};
`;

export const OffersCardContentContainer = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  text-align: center;
`;

export const OfferImage = styled.img`
  width: 50px;
  height: 50px;
`;

export const OffersButtonContainer = styled.div`
  margin-top: -20px;
  z-index: 10;
`;
