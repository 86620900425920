import styled from "styled-components";

export const AboutContainer = styled.div`
  width: 100%;
  display: flex;

  /* background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(5, 8, 7, 1) 100%); */

  background: #020304;

  min-width: 280px;

  padding: 40px 0px;

  justify-content: center;

  @media (min-width: 1200px) {
    padding: 80px 0px;
    min-height: 400px;
  }
`;

export const AboutImageContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const AboutVideo = styled.video`
  height: 380px;
  width: 670px;
  margin-right: -40px;
  margin-top: 50px;

  opacity: 85%;
`;

export const AboutImage = styled.img`
  height: 380px;
  width: 670px;
  margin-right: -40px;
  margin-top: 50px;
  z-index: 10;
`;

export const AboutContentContainer = styled.div`
  width: 80%;

  background: #212121;
  border-radius: 30px;
  text-align: center;

  padding: 30px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 10;

  max-width: 500px;

  @media (min-width: 1200px) {
    width: 40%;
    border-radius: 30px;
    margin-right: -40px;
    max-width: 500px;

    padding: 40px 10px;
  }
`;

export const AboutTitleContainer = styled.div`
  margin-bottom: 20px;
`;

export const CompanyNameContainer = styled.div``;

export const TextContainer = styled.div`
  margin: 40px 0px;
  padding: 0px 5px;
  max-width: 400px;

  @media (max-width: 400px) {
    margin-top: 30px;
  }
`;

export const BoldSpan = styled.span`
  font-weight: 700;
`;

export const ButtonContainer = styled.div`
  margin: 10px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 240px;
`;
