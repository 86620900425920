import React, { useCallback, useEffect, useState } from "react";
import { Button, H1, H6 } from "../../../../App.style";

import cardImage1 from "../../../../assets/offers/1.svg";
import cardImage2 from "../../../../assets/offers/2.svg";
import cardImage3 from "../../../../assets/offers/3.svg";
import cardImage4 from "../../../../assets/offers/4.svg";
import cardImage5 from "../../../../assets/offers/5.svg";
import cardImage6 from "../../../../assets/offers/6.svg";
import cardImage7 from "../../../../assets/offers/7.svg";
import cardImage8 from "../../../../assets/offers/8.svg";
import cardImage9 from "../../../../assets/offers/9.svg";
import cardImage10 from "../../../../assets/offers/10.svg";
import {
  OfferImage,
  OffersButtonContainer,
  OffersCardContainer,
  OffersCardContentContainer,
  OffersContainer,
  OffersContentContainer,
  OffersTitleContainer,
} from "./Offerss.style";
import { useTranslation } from "react-i18next";

const Offers = () => {
  const [showAll, setShowAll] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { t } = useTranslation();

  interface OfferCard {
    text: string;
    image: string;
  }

  const cards: OfferCard[] = [
    {
      text: t("offers.cards.card1"),
      image: cardImage1,
    },
    {
      text: t("offers.cards.card2"),
      image: cardImage2,
    },
    {
      text: t("offers.cards.card3"),
      image: cardImage3,
    },
    {
      text: t("offers.cards.card4"),
      image: cardImage4,
    },
    {
      text: t("offers.cards.card5"),
      image: cardImage5,
    },
    {
      text: t("offers.cards.card6"),
      image: cardImage6,
    },
    {
      text: t("offers.cards.card7"),
      image: cardImage7,
    },
    {
      text: t("offers.cards.card8"),
      image: cardImage8,
    },
    {
      text: t("offers.cards.card9"),
      image: cardImage9,
    },
    {
      text: t("offers.cards.card10"),
      image: cardImage10,
    },
  ];

  const handleOfferButonClick = () => {
    if (showAll) {
      handleScroll("offers");
    } else {
      setShowAll((prev) => !prev);
    }
  };

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.pageYOffset - 0,
        behavior: "smooth",
      });

      setTimeout(() => {
        setShowAll(false);
      }, 100);
    }
  };

  return (
    <OffersContainer>
      <OffersTitleContainer>
        <H1>{t("offers.title")}</H1>
      </OffersTitleContainer>
      <OffersContentContainer>
        {cards
          .slice(0, isMobile && !showAll ? 3 : cards.length)
          .map((card, index) => {
            return (
              <OffersCardContainer
                $shouldDisplay={index < 3 || (isMobile && showAll) || !isMobile}
                key={`Offers-card-${index}`}
              >
                <OffersCardContentContainer>
                  <OfferImage src={card.image} />
                  <H6>{card.text}</H6>
                </OffersCardContentContainer>
              </OffersCardContainer>
            );
          })}
      </OffersContentContainer>
      {isMobile && (
        <OffersButtonContainer>
          <Button onClick={handleOfferButonClick}>
            {showAll ? t("offers.showLess") : t("offers.showMore")}
          </Button>
        </OffersButtonContainer>
      )}
    </OffersContainer>
  );
};

export default Offers;
