import styled, { css, keyframes } from "styled-components";

export const RealizationsContainer = styled.div`
  min-height: 100vh;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LandingSectionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: black;

  @media (min-width: 768px) {
    background: linear-gradient(to right, #1e1e1e 50%, #000000 50%);
    margin-bottom: 50px;
  }
`;

export const LandingContainer = styled.div`
  width: 100%;
  height: calc(90vh - 80px);
  max-width: 2500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;

  @media (min-width: 768px) {
    height: calc(100vh - 80px);
    flex-direction: row;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 60%;

  display: flex;
  gap: 40px;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #1e1e1e;
  z-index: 0;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (min-width: 768px) {
    flex-direction: column;
    height: 100%;
    width: 40%;
  }

  @media (min-width: 1200px) {
    width: 30%;
  }
`;

export const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 50px;

  @media (max-width: 768px) {
    width: 80%;
    max-width: 500px;
  }
`;

export const VideoContainer = styled.div`
  width: 90%;
  height: 40%;
  display: flex;
  max-width: 500px;

  position: relative;

  z-index: 1;

  @media (min-width: 768px) {
    max-width: 3000px;
    overflow: hidden;
    width: 60%;
    height: 100%;
    background: #1e1e1e;
  }

  @media (min-width: 1200px) {
    width: 70%;
  }
`;

export const Video = styled.video`
  position: absolute;
  top: -50px;
  left: 0px;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;

  @media (min-width: 768px) {
    top: 0px;
    flex: 0.6;
    border-radius: 0px;
    height: 100vh;
    object-fit: cover;
    object-position: 25% center;
  }
`;

//sections

export const RealizationSectionContainer = styled.div<{ $grey: boolean }>`
  width: 100%;

  background: ${(props) => (props.$grey ? "#1E1E1E" : "#050807")};

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    gap: 25px;
  }
  padding: 50px 0px;
`;

export const RealizationSectionTitleContainer = styled.div`
  width: 90%;

  max-width: 400px;

  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (min-width: 768px) {
    padding-left: 0px;
    width: 80%;
    max-width: 1120px;
  }
`;

export const RealizationSectionContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 400px;

  @media (min-width: 768px) {
    max-width: 1400px;
  }
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const RealizationSectionCardContainer = styled.div<{
  $shouldDisplay: boolean;
}>`
  width: 270px;
  height: 150px;

  margin: 10px;

  display: flex;

  border: 1px solid #424242;
  border-radius: 32px;

  position: relative;
  overflow: hidden;

  visibility: ${(props) => (props.$shouldDisplay ? "visible" : "hidden")};
  animation: ${(props) =>
    props.$shouldDisplay
      ? css`
          ${fadeInUp} 0.5s forwards
        `
      : "none"};
`;

export const RealizationImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  cursor: pointer;
`;

export const RealizationSectionButtonContainer = styled.div`
  margin-top: -20px;
  z-index: 10;
`;
