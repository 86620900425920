import { styled } from "styled-components";

export const AssistanceContainer = styled.div`
  width: 100%;
  background: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 600px;
  padding: 40px 0px;

  @media (min-width: 768px) {
    padding-top: 60px;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: center;

    padding: 0px;
  }
`;

export const LawetaContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    border-radius: 32px;
    background: #2b74b9;
    padding: 30px;
    gap: 40px;
    padding-bottom: 00px;
    padding-right: 0px;
  }
`;

export const LawetaTextContainer = styled.div`
  margin-top: 30px;

  margin-left: 20px;
  width: 200px;
  height: 100%;

  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 1200px) {
    margin-top: -80px;
  }
`;

export const LawetaImage = styled.img``;

export const LawetaMobileContainer = styled.div`
  position: absolute;
  border: 2px solid #2b74b9;
  background: #1e1e1e;
  padding: 20px 30px;
  border-radius: 32px;
  bottom: -63px;
  text-align: center;

  @media (min-width: 768px) {
    display: none;
  }
`;

//assistance contact

export const AsistanceContactContainer = styled.div`
  margin-top: 100px;

  display: flex;
  flex-direction: column;

  gap: 30px;

  margin-right: 40px;

  @media (min-width: 768px) {
    margin-top: 40px;
    flex-direction: row;
    gap: 80px;

    width: 100%;
    justify-content: center;
  }

  @media (min-width: 1200px) {
    margin-left: 80px;
    width: 20%;

    flex-direction: column;
    max-width: 400px;
    min-width: 300px;
  }
`;

export const AssistanceContact = styled.div`
  display: flex;

  align-items: center;

  @media (min-width: 768px) {
    margin-right: 10px;
  }
`;

export const AssistanceContactContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 144px;

  gap: 8px;
`;

export const AssistanceIcon = styled.img<{ $phone?: boolean }>`
  margin-right: 20px;

  width: 110px;

  @media (min-width: 768px) {
    width: 150px;
    margin-right: ${(props) => (props.$phone ? "-20px" : "20px")};
  }

  @media (min-width: 1200px) {
    margin-right: 20px;
  }
`;
