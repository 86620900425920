import React, { useEffect } from "react";
import {
  LandingContainer,
  LandingSectionContainer,
  RealizationsContainer,
  TextContainer,
  TextContentContainer,
  Video,
  VideoContainer,
} from "./Realizations.style";
import realizationVideo from "../../assets/realizations/realizations-video.mp4";
import { ColoredSpan, H1, H4 } from "../../App.style";
import RealizationComponent from "./RealizationComponent";
import Section from "../home/Section";
import { useTranslation } from "react-i18next";
import i18n from "../../translations/i18n";

import realization1_1 from "../../assets/realizations/1_BMW_3_G20_320d/1.jpg";
import realization1_2 from "../../assets/realizations/1_BMW_3_G20_320d/2.jpg";
import realization1_3 from "../../assets/realizations/1_BMW_3_G20_320d/3.jpg";
import realization1_4 from "../../assets/realizations/1_BMW_3_G20_320d/4.jpg";
import realization1_5 from "../../assets/realizations/1_BMW_3_G20_320d/5.jpg";
import realization1_6 from "../../assets/realizations/1_BMW_3_G20_320d/6.jpg";
import realization1_7 from "../../assets/realizations/1_BMW_3_G20_320d/7.jpg";
import realization1_8 from "../../assets/realizations/1_BMW_3_G20_320d/8.jpg";

import realization2_1 from "../../assets/realizations/2_BMW_5_G30_520d/1.jpg";
import realization2_2 from "../../assets/realizations/2_BMW_5_G30_520d/2.jpg";
import realization2_3 from "../../assets/realizations/2_BMW_5_G30_520d/3.jpg";
import realization2_4 from "../../assets/realizations/2_BMW_5_G30_520d/4.jpg";
import realization2_5 from "../../assets/realizations/2_BMW_5_G30_520d/5.jpg";

import realization3_1 from "../../assets/realizations/3_BMW_8_G14/1.jpg";
import realization3_2 from "../../assets/realizations/3_BMW_8_G14/2.jpg";
import realization3_3 from "../../assets/realizations/3_BMW_8_G14/3.jpg";
import realization3_4 from "../../assets/realizations/3_BMW_8_G14/4.jpg";
import realization3_5 from "../../assets/realizations/3_BMW_8_G14/5.jpg";
import realization3_6 from "../../assets/realizations/3_BMW_8_G14/6.jpg";
import realization3_7 from "../../assets/realizations/3_BMW_8_G14/7.jpg";

import realization4_1 from "../../assets/realizations/4_BMW_X5_F15/1.jpg";
import realization4_2 from "../../assets/realizations/4_BMW_X5_F15/2.jpg";
import realization4_3 from "../../assets/realizations/4_BMW_X5_F15/3.jpg";
import realization4_4 from "../../assets/realizations/4_BMW_X5_F15/4.jpg";
import realization4_5 from "../../assets/realizations/4_BMW_X5_F15/5.jpg";
import realization4_6 from "../../assets/realizations/4_BMW_X5_F15/6.jpg";
import realization4_7 from "../../assets/realizations/4_BMW_X5_F15/7.jpg";
import realization4_8 from "../../assets/realizations/4_BMW_X5_F15/8.jpg";
import realization4_9 from "../../assets/realizations/4_BMW_X5_F15/9.jpg";
import realization4_10 from "../../assets/realizations/4_BMW_X5_F15/10.jpg";
import realization4_11 from "../../assets/realizations/4_BMW_X5_F15/11.jpg";

import realization5_1 from "../../assets/realizations/5_BMW_G3_2019/1.jpg";
import realization5_2 from "../../assets/realizations/5_BMW_G3_2019/2.jpg";
import realization5_3 from "../../assets/realizations/5_BMW_G3_2019/3.jpg";
import realization5_4 from "../../assets/realizations/5_BMW_G3_2019/4.jpg";
import realization5_5 from "../../assets/realizations/5_BMW_G3_2019/5.jpg";
import realization5_6 from "../../assets/realizations/5_BMW_G3_2019/6.jpg";
import realization5_7 from "../../assets/realizations/5_BMW_G3_2019/7.jpg";
import realization5_8 from "../../assets/realizations/5_BMW_G3_2019/8.jpg";

import realization6_1 from "../../assets/realizations/6_BMW_E60/przed.jpg";
import realization6_2 from "../../assets/realizations/6_BMW_E60/po.jpg";

import realization7_1 from "../../assets/realizations/7_carplay/1.jpg";
import realization7_2 from "../../assets/realizations/7_carplay/2.jpg";
import realization7_3 from "../../assets/realizations/7_carplay/3.jpg";
import realization7_4 from "../../assets/realizations/7_carplay/4.jpg";

import realization8_1 from "../../assets/realizations/8_electronics/1.jpg";
import realization8_2 from "../../assets/realizations/8_electronics/2.jpg";
import realization8_3 from "../../assets/realizations/8_electronics/3.jpg";
import realization8_4 from "../../assets/realizations/8_electronics/4.jpg";
import realization8_5 from "../../assets/realizations/8_electronics/5.jpg";
import realization8_6 from "../../assets/realizations/8_electronics/6.jpg";
import realization8_7 from "../../assets/realizations/8_electronics/7.jpg";
import realization8_8 from "../../assets/realizations/8_electronics/8.jpg";
import realization8_9 from "../../assets/realizations/8_electronics/9.jpg";
import realization8_10 from "../../assets/realizations/8_electronics/10.jpeg";
import realization8_11 from "../../assets/realizations/8_electronics/11.jpg";
import realization8_12 from "../../assets/realizations/8_electronics/12.jpg";

const realization_1_images = [
  realization1_1,
  realization1_2,
  realization1_3,
  realization1_4,
  realization1_5,
  realization1_6,
  realization1_7,
  realization1_8,
];
const realization_2_images = [
  realization2_1,
  realization2_2,
  realization2_3,
  realization2_4,
  realization2_5,
];

const realization_3_images = [
  realization3_1,
  realization3_2,
  realization3_3,
  realization3_4,
  realization3_5,
  realization3_6,
  realization3_7,
];

const realization_4_images = [
  realization4_1,
  realization4_2,
  realization4_3,
  realization4_4,
  realization4_5,
  realization4_6,
  realization4_7,
  realization4_8,
  realization4_9,
  realization4_10,
  realization4_11,
];

const realization_5_images = [
  realization5_1,
  realization5_2,
  realization5_3,
  realization5_4,
  realization5_5,
  realization5_6,
  realization5_7,
  realization5_8,
];

const realization_6_images = [realization6_1, realization6_2];

const realization_7_images = [
  realization7_1,
  realization7_2,
  realization7_3,
  realization7_4,
];

const realization_8_images = [
  realization8_1,
  realization8_2,
  realization8_3,
  realization8_4,
  realization8_5,
  realization8_6,
  realization8_7,
  realization8_8,
  realization8_9,
  realization8_10,
  realization8_11,
  realization8_12,
];

interface RealizationProps {
  loading: boolean;
  stopLoading: () => void;
}

const Realizations: React.FC<RealizationProps> = ({ loading, stopLoading }) => {
  useEffect(() => {
    if (loading) {
      // Only set the timer if loading is true
      const timerId = setTimeout(() => {
        stopLoading();
      }, 2000); // 2 seconds

      return () => clearTimeout(timerId); // Clear the timeout if the component is unmounted
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const { t } = useTranslation();

  const language = i18n.language;

  return (
    <RealizationsContainer>
      <LandingSectionContainer>
        <LandingContainer>
          <TextContainer>
            <TextContentContainer>
              <H1>{t("realizations.title")}</H1>
              <div>
                <H4>{t("realizations.text1")}</H4>
                <H4>
                  {t("realizations.text2")}{" "}
                  <ColoredSpan>{t("realizations.text3")}</ColoredSpan>
                </H4>
              </div>
            </TextContentContainer>
          </TextContainer>
          <VideoContainer>
            <Video autoPlay muted playsInline loop onLoadedData={stopLoading}>
              <source src={realizationVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
          </VideoContainer>
        </LandingContainer>
      </LandingSectionContainer>

      <Section id={"1"}>
        <RealizationComponent
          title={"BMW 3 G20 320d B47 2019"}
          description={
            language === "en"
              ? "timing chain replacement"
              : "wymiana łańcucha rozrządu"
          }
          images={realization_1_images}
          sectionId={"1"}
        />
      </Section>

      <Section id={"2"}>
        <RealizationComponent
          title={"BMW 5 G30 520d B47D 2018"}
          description={
            language === "en"
              ? "8hp automatic transmission service"
              : "serwis automatycznej skrzyni biegów 8hp"
          }
          images={realization_2_images}
          sectionId={"2"}
        />
      </Section>

      <Section id={"3"}>
        <RealizationComponent
          title={"BMW 8 G14 N63B44"}
          description={
            language === "en"
              ? "replacement of damaged parts"
              : "wymiana uszkodzonych elementów"
          }
          images={realization_3_images}
          sectionId={"3"}
        />
      </Section>

      <Section id={"4"}>
        <RealizationComponent
          title={"BMW X5 F15 550d N57"}
          description={
            language === "en"
              ? "timing chain replacement, valve cover replacement, and intake system cleaning"
              : "wymiana łańcucha rozrządu, pokrywy zaworów i czyszczenie układu dolotowego"
          }
          images={realization_4_images}
          sectionId={"4"}
        />
      </Section>

      <Section id={"5"}>
        <RealizationComponent
          title={"BMW 5' G3 2019"}
          description={
            language === "en"
              ? "brake system service"
              : "serwis układu hamulcowego"
          }
          images={realization_5_images}
          sectionId={"5"}
        />
      </Section>

      <Section id={"6"}>
        <RealizationComponent
          title={"BMW 5' E60 2010"}
          description={
            language === "en"
              ? "intake manifold head cleaning with walnut shells"
              : "czyszczenie układu dolotowego głowicy łupinami orzecha "
          }
          images={realization_6_images}
          sectionId={"6"}
        />
      </Section>

      <Section id={"7"}>
        <RealizationComponent
          title={language === "en" ? "CarPlay upgrade" : "Doposażenie CarPlay"}
          description={""}
          images={realization_7_images}
          sectionId={"7"}
        />
      </Section>

      <Section id={"8"}>
        <RealizationComponent
          title={
            language === "en" ? "Electronics repair" : "Naprawa elektroniki"
          }
          description={""}
          images={realization_8_images}
          sectionId={"8"}
        />
      </Section>
    </RealizationsContainer>
  );
};

export default Realizations;
