import styled from "styled-components";

export const HomeContainer = styled.div`
  min-height: 100vh;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LandingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: black;
`;

export const LandingVideoContainer = styled.div`
  position: relative;
  max-width: 2400px;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  background: black;

  @media (min-width: 768px) {
    height: 100vh;
  }
`;

export const StyledPoster = styled.img`
  position: absolute;

  width: 100%;
  height: 100%;
  object-fit: scale-down;
  transform: scale(1.7);

  @media (min-width: 768px) {
    transform: scale(1.5);
  }

  @media (min-width: 1100px) {
    transform: scale(1);
    object-fit: cover;
  }
`;

export const StyledVideo = styled.video`
  top: 30px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  transform: scale(1.7);

  @media (min-width: 768px) {
    transform: scale(1.5);
  }

  @media (min-width: 1100px) {
    transform: scale(1);
    object-fit: cover;
  }
`;

export const CompanyName = styled.div`
  position: absolute;
  top: 20%;
  left: 20%;
  transform: translate(-50%, -50%);

  text-align: center;
`;
