import React, { useEffect, useRef, useState } from "react";
import { H6 } from "../../App.style";
import arrowDown from "../../assets/navbar/arrow-down.png";
import arrowUp from "../../assets/navbar/arrow-up.png";
import { useTranslation } from "react-i18next";
import polishFlag from "../../assets/navbar/pl-flag.png";
import englishFlag from "../../assets/navbar/en-flag.jpeg";
import {
  SelectorContainer,
  SelectedOption,
  FlagIcon,
  CountryText,
  OptionsContainer,
  OptionSelected,
  FlagImg,
  Optionn,
} from "./LanguageSelector.style";

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const initialLanguage = i18n.language?.toUpperCase() === "EN" ? "EN" : "PL";
  const [selectedLanguage, setSelectedLanguage] = useState<"PL" | "EN">(
    initialLanguage
  );
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initialLanguage !== selectedLanguage) {
      setSelectedLanguage(initialLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLanguage]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectLanguage = (language: "PL" | "EN") => {
    setSelectedLanguage(language);

    const i18language = language.toLowerCase();

    i18n.changeLanguage(i18language);

    setIsOpen(false);
  };

  return (
    <SelectorContainer onClick={toggleDropdown} ref={containerRef}>
      <SelectedOption>
        <FlagIcon>
          {selectedLanguage === "EN" ? (
            <FlagImg src={englishFlag} />
          ) : (
            <FlagImg src={polishFlag} />
          )}
        </FlagIcon>
        <CountryText>
          <H6>{selectedLanguage}</H6>
        </CountryText>
        <img
          style={{ marginLeft: 15 }}
          alt="arrow"
          src={isOpen ? arrowUp : arrowDown}
        />
      </SelectedOption>
      {isOpen && (
        <OptionsContainer>
          <Optionn onClick={() => selectLanguage("PL")}>
            <FlagIcon>
              <FlagImg src={polishFlag} />
            </FlagIcon>
            <H6>PL</H6>
            {selectedLanguage === "PL" && <OptionSelected />}
          </Optionn>

          <Optionn
            onClick={() => {
              selectLanguage("EN");
            }}
          >
            <FlagIcon>
              <FlagImg src={englishFlag} />
            </FlagIcon>
            <H6>EN</H6>
            {selectedLanguage === "EN" && <OptionSelected />}
          </Optionn>
        </OptionsContainer>
      )}
    </SelectorContainer>
  );
};

export default LanguageSelector;
