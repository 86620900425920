import React from "react";
import {
  ContactContainer,
  FindUsContainer,
  FindUsTitleContainer,
  InfoContainer,
  InfoContentContainer,
  InfoDetailsContainer,
  InfoDetailsContentContainer,
  InfoIcon,
  LocationContainer,
  MapLink,
  PhoneLeftContainer,
  PhoneRightContainer,
  StyledLink,
} from "./Contact.style";
import { ColoredSpan, H1, H4 } from "../../../../App.style";
import clockIcon from "../../../../assets/contact/clock.svg";
import phoneIcon from "../../../../assets/contact/phone.svg";
import locationIcon from "../../../../assets/contact/location.svg";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <ContactContainer>
      <InfoContainer>
        <H1>{t("contact.title")}</H1>

        <InfoDetailsContainer>
          <InfoContentContainer>
            <InfoIcon src={clockIcon} />
            <InfoDetailsContentContainer>
              <div style={{ display: "flex" }}>
                <PhoneLeftContainer>
                  <H4>
                    <ColoredSpan>{t("contact.mondayToFriday")}</ColoredSpan>
                  </H4>
                </PhoneLeftContainer>
                <PhoneRightContainer>
                  <H4>9:00-17:00</H4>
                </PhoneRightContainer>
              </div>

              <div style={{ display: "flex" }}>
                <PhoneLeftContainer>
                  <H4>
                    <ColoredSpan>{t("contact.saturday")}</ColoredSpan>
                  </H4>
                </PhoneLeftContainer>
                <PhoneRightContainer>
                  <H4>9:00-13:00</H4>
                </PhoneRightContainer>
              </div>
            </InfoDetailsContentContainer>
          </InfoContentContainer>
          <InfoContentContainer>
            <InfoIcon src={phoneIcon} />
            <InfoDetailsContentContainer>
              <StyledLink href="tel:+48698899266">
                <H4>+48 698 899 266</H4>
              </StyledLink>
            </InfoDetailsContentContainer>
          </InfoContentContainer>
          <InfoContentContainer>
            <InfoIcon src={locationIcon} />
            <InfoDetailsContentContainer $location>
              <StyledLink
                href="https://www.google.com/maps/place/M-CAR+technology/@49.7515488,18.6473212,17z/data=!3m1!4b1!4m6!3m5!1s0x47140353ffcc492b:0xe102712bb951af0b!8m2!3d49.7515488!4d18.6498961!16s%2Fg%2F11ff1_742l?entry=ttu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <H4>Zamarski,</H4>
                <H4>{t("contact.street")}. Główna 61</H4>
              </StyledLink>
            </InfoDetailsContentContainer>
          </InfoContentContainer>
        </InfoDetailsContainer>
      </InfoContainer>

      <FindUsContainer>
        <FindUsTitleContainer>
          <H1>
            {t("contact.findUs1")}{" "}
            <ColoredSpan>{t("contact.findUs2")}</ColoredSpan>!
          </H1>
        </FindUsTitleContainer>
        <LocationContainer>
          <iframe
            title="m-car-location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2576.251358954371!2d18.657879876518003!3d49.78133787147208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471403ce707a7caf%3A0xbb47d18a22250b3e!2sG%C5%82%C3%B3wna%2061%2C%2043-419%20Zamarski!5e0!3m2!1spl!2spl!4v1715364896522!5m2!1spl!2spl"
            width="100%"
            height="100%"
            style={{ border: 0, borderRadius: 30 }}
          />
          <MapLink
            href="https://www.google.com/maps/place/G%C5%82%C3%B3wna+61,+43-419+Zamarski/@49.7813379,18.6578799,17z/data=!3m1!4b1!4m6!3m5!1s0x471403ce707a7caf:0xbb47d18a22250b3e!8m2!3d49.7813379!4d18.6604548!16s%2Fg%2F11vc72_cyt?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Open in Google Maps"
          ></MapLink>
        </LocationContainer>
      </FindUsContainer>
    </ContactContainer>
  );
};

export default Contact;
