export const translationsPl = {
  navbar: {
    about: "O nas",
    offer: "Oferta",
    realizations: "Realizacje",
    contact: "Kontakt",
  },

  about: {
    title: "O nas",
    text1:
      "Jesteśmy firmą handlowo-usługową specjalizującą się w szerokim zakresie usług związanych z ",
    text1bold: "samochodami marki BMW",
    text2: "Nasza misja to dostarczenie ",
    text2bold: "najwyższej jakości usług",
    text2continue: ", które zaspokoją nawet najbardziej wymagających klientów.",
    buttonText: "Formularz kontaktowy",
  },

  contactForm: {
    title1: "Formularz ",
    title2: "kontaktowy",
    submittedText:
      "Dziękujemy za wysłanie wiadomości. Rozpatrzymy Twoje zgłoszenie i skontaktujemy się w najbliższym możliwym terminie.",
    inputs: {
      name: "Imie",
      phone: "Numer telefonu",
      email: "Email",
      vin: "VIN",
      description: "Opis usterki",
    },
    errorMessage: "*Wszystkie pola muszą zostać wypełnione",
    buttonText: "Wyślij formularz",
    buttonSent: "Wysyłanie...",
  },

  offers: {
    title: "Co oferujemy?",
    cards: {
      card1: "Kodowanie, programowanie i diagnostykę komputerową",
      card2: "Komputerową inspekcję wyjazdową przed zakupem",
      card3: "Indywidualizację i aktywację dodatkowych funkcji",
      card4: "Aktualizację oprogramowania i sterowników",
      card5: "Tuning elektroniczny",
      card6: "Rozwiązywanie problemów z systemami Adblue, SCR, EGR, DPF, FAP",
      card7: "Usuwanie ograniczeń mocy",
      card8: "Wsparcie dla zakładów mechanicznych",
      card9: "Serwis mechaniczny z wpisem na serwer",
      card10: "Elektromechanikę",
    },
    showMore: "Zobacz więcej",
    showLess: "Pokaż mniej",
  },

  contact: {
    title: "Kontakt",
    mondayToFriday: "pn-pt",
    saturday: "sb",
    street: "ul",
    findUs1: "Znajdziesz nas",
    findUs2: "tutaj",
  },

  assistance: {
    title1: "Potrzebna",
    title2: "pomoc drogowa",
    or: "lub",
    polish: "Polski",
    czech: "Czeski",
    english: "Angielski",
  },

  realizations: {
    title: "Realizacje",
    text1: "Zobacz jak przywracamy auta",
    text2: "do",
    text3: "pełnej sprawności",
    showLess: "Pokaż mniej",
    showMore: "Zobacz więcej",
  },
};
