import styled from "styled-components";

export const ContactContainer = styled.div`
  width: 100%;
`;

export const InfoContainer = styled.div`
  width: 100%;
  background: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 0px;

  @media (min-width: 768px) {
    padding-top: 60px;
  }
`;

export const InfoDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  margin: 30px 0px;
  margin-bottom: 10px;
  margin-left: -30px;

  @media (min-width: 768px) {
    margin-left: 0px;
    margin: 60px 0px;
    flex-direction: row;
    max-width: 1000px;

    width: 80%;
    justify-content: space-around;
  }
`;

export const InfoContentContainer = styled.div`
  display: flex;

  @media (min-width: 768px) {
    flex-direction: column;
    align-items: center;

    gap: 30px;
  }
`;

export const PhoneLeftContainer = styled.div`
  width: 70px;
`;

export const PhoneRightContainer = styled.div``;

export const InfoIcon = styled.img`
  width: 110px;

  @media (min-width: 768px) {
    width: 150px;
  }
`;

export const InfoDetailsContentContainer = styled.div<{ $location?: boolean }>`
  display: flex;
  flex-direction: column;

  justify-content: center;

  @media (min-width: 768px) {
    text-align: ${(props) => props.$location && "center"};
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: block;

  &:hover {
    text-decoration: underline;
  }
`;

//find us

export const FindUsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 0px;

  @media (min-width: 768px) {
    padding: 60px 0px;
    padding-top: 60px;
  }

  @media (min-width: 1200px) {
    padding: 80px 0px;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 80px;
  }
`;

export const FindUsTitleContainer = styled.div`
  margin-bottom: 50px;

  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 1200px) {
    width: 200px;
  }
`;

export const LocationContainer = styled.div`
  position: relative;
  width: 90%;
  height: 400px;
  border-radius: 30px;

  @media (min-width: 768px) {
    width: 80%;
    max-width: 700px;
  }
`;

export const MapLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  @media (min-width: 768px) {
    display: none;
  }
`;
