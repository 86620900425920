import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LeftContainer,
  NavbarContainer,
  NavbarExtendedContainer,
  NavbarInnerContainer,
  RightContainer,
  NavbarLinkContainer,
  ProjectName,
  NavItem,
  Checkbox,
  HamburgerContainer,
  HamburgerLines,
  Line,
} from "./Navbar.style";
import logo from "../..//assets/navbar/logo.svg";
import { H4 } from "../../App.style";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";

interface NavbarProps {
  startLoading: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ startLoading }) => {
  const [extendNavbar, setExtendNavbar] = useState<boolean>(false);
  const [scrollTarget, setScrollTarget] = useState<string>();
  const location = useLocation();

  const handleExtendNavbar = () => {
    setExtendNavbar((curr) => !curr);
  };

  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScroll = (id: string) => {
    if (location.pathname !== "/") {
      navigate("/");
      setScrollTarget(id); // Set the scroll target for useEffect to use later
    } else {
      scrollToTarget(id);
    }
  };

  const scrollToTarget = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      setExtendNavbar(false);
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.pageYOffset - 120,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (location.pathname === "/" && scrollTarget) {
      startLoading();
      // Introduce a delay before attempting to scroll
      const timeoutId = setTimeout(() => {
        scrollToTarget(scrollTarget);
        setScrollTarget(undefined); // Reset the scroll target
      }, 400); // Adjust the delay duration as needed

      // Cleanup timeout on component unmount or if conditions change
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, scrollTarget]);

  const handleRealizationsClick = () => {
    setExtendNavbar(false);

    if (location.pathname !== "/realizacje") {
      startLoading();
      navigate("/realizacje");
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const { t } = useTranslation();

  return (
    <NavbarContainer $extendedNavbar={extendNavbar}>
      <NavbarInnerContainer>
        <LeftContainer onClick={handleLogoClick}>
          <img style={{ width: 100, height: 50 }} src={logo} alt="logo" />
          <ProjectName>
            <H4>M-CAR Technology</H4>
          </ProjectName>
        </LeftContainer>
        <RightContainer>
          <NavbarLinkContainer>
            <NavItem $hideOnMobile onClick={() => handleScroll("about")}>
              <H4>{t("navbar.about")}</H4>
            </NavItem>
            <NavItem $hideOnMobile onClick={() => handleScroll("offers")}>
              <H4>{t("navbar.offer")}</H4>
            </NavItem>

            <NavItem $hideOnMobile onClick={handleRealizationsClick}>
              <H4>{t("navbar.realizations")}</H4>
            </NavItem>

            <NavItem $hideOnMobile onClick={() => handleScroll("contact")}>
              <H4>{t("navbar.contact")}</H4>
            </NavItem>
            <NavItem $hideOnMobile>
              <LanguageSelector />
            </NavItem>

            <HamburgerContainer onClick={handleExtendNavbar}>
              <Checkbox checked={extendNavbar} readOnly />
              <HamburgerLines>
                <Line $top $open={extendNavbar} />
                <Line $middle $open={extendNavbar} />
                <Line $bottom $open={extendNavbar} />
              </HamburgerLines>
            </HamburgerContainer>
          </NavbarLinkContainer>
        </RightContainer>
      </NavbarInnerContainer>
      {extendNavbar && (
        <NavbarExtendedContainer isOpen={extendNavbar}>
          <NavItem onClick={() => handleScroll("about")}>
            <H4>{t("navbar.about")}</H4>
          </NavItem>
          <NavItem onClick={() => handleScroll("offers")}>
            <H4>{t("navbar.offer")}</H4>
          </NavItem>
          <NavItem onClick={handleRealizationsClick}>
            <H4>{t("navbar.realizations")}</H4>
          </NavItem>

          <NavItem onClick={() => handleScroll("contact")}>
            <H4>{t("navbar.contact")}</H4>
          </NavItem>

          <NavItem>
            <LanguageSelector />
          </NavItem>
        </NavbarExtendedContainer>
      )}
    </NavbarContainer>
  );
};

export default Navbar;
