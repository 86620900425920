import React from "react";
import {
  AsistanceContactContainer,
  AssistanceContainer,
  AssistanceIcon,
  AssistanceContactContent,
  LawetaContainer,
  LawetaImage,
  LawetaMobileContainer,
  LawetaTextContainer,
  AssistanceContact,
} from "./Assistance.style";
import lawetaImage from "../../../../assets/assistance/laweta.png";
import phoneIcon from "../../../../assets/assistance/phone.png";
import languageIcon from "../../../../assets/assistance/language.png";
import { ColoredSpan, H1, H2, H4 } from "../../../../App.style";
import { StyledLink } from "../contact/Contact.style";
import { useTranslation } from "react-i18next";

const Assistance = () => {
  const { t } = useTranslation();

  return (
    <AssistanceContainer>
      <LawetaContainer>
        <LawetaTextContainer>
          <H1>
            {t("assistance.title1")}{" "}
            <ColoredSpan>{t("assistance.title2")}</ColoredSpan>?
          </H1>
        </LawetaTextContainer>

        <LawetaImage src={lawetaImage} />

        <LawetaMobileContainer>
          <H2>{t("assistance.title1")}</H2>
          <H2>
            <ColoredSpan>{t("assistance.title2")}</ColoredSpan>?
          </H2>
        </LawetaMobileContainer>
      </LawetaContainer>

      <AsistanceContactContainer>
        <AssistanceContact>
          <AssistanceIcon src={phoneIcon} $phone />
          <AssistanceContactContent>
            <StyledLink href="tel:+48602360064">
              <H4>+48 602 360 064</H4>
            </StyledLink>
            <H4 style={{ fontWeight: 700 }}>{t("assistance.or")}</H4>
            <StyledLink href="tel:+48501239575">
              <H4>+48 501 239 575</H4>
            </StyledLink>
          </AssistanceContactContent>
        </AssistanceContact>

        <AssistanceContact>
          <AssistanceIcon src={languageIcon} />
          <AssistanceContactContent>
            <H4>{t("assistance.polish")}</H4>
            <H4>{t("assistance.czech")}</H4>
            <H4>{t("assistance.english")}</H4>
          </AssistanceContactContent>
        </AssistanceContact>
      </AsistanceContactContainer>
    </AssistanceContainer>
  );
};

export default Assistance;
