import React, { useEffect, useState } from "react";
import {
  ModalBackdrop,
  ModalContent,
  CloseButton,
  StyledForm,
  StyledInput,
  ValidationIcon,
  StyledTextarea,
  InputWrapper,
  ButtonContainer,
  ModalTitleContainer,
  SubmittedFormContainer,
  AllFieldsAreRequiredContainer,
  InputGroup,
  HalfWidthWrapper,
} from "./ContactFormModal.style";
import { Button, ColoredSpan, H1, H4, H6 } from "../../App.style";
import { useForm } from "@formspree/react";
import checkIcon from "../../assets/about/check-icon.svg";
import xIcon from "../../assets/about/x-icon.svg";
import { useTranslation } from "react-i18next";

interface ContactFormModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ContactFormModal: React.FC<ContactFormModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [formValues, setFormValues] = useState({
    Email: "",
    Imie: "",
    Numer_telefonu: "",
    VIN: "",
    Opis_usterki: "",
  });

  const [validationStatus, setValidationStatus] = useState({
    Email: false,
    Imie: false,
    Numer_telefonu: false,
    VIN: false,
    Opis_usterki: false,
  });

  function validateEmail(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    validateField(name, value);
  };

  const validateField = (name: string, value: string) => {
    switch (name) {
      case "Email":
        setValidationStatus((prevStatus) => ({
          ...prevStatus,
          Email: validateEmail(value),
        }));
        break;
      case "Imie":
        setValidationStatus((prevStatus) => ({
          ...prevStatus,
          Imie: value.length > 0,
        }));
        break;
      case "Numer_telefonu":
        setValidationStatus((prevStatus) => ({
          ...prevStatus,
          Numer_telefonu: value.length >= 9 && value.length < 13,
        }));
        break;
      case "VIN":
        setValidationStatus((prevStatus) => ({
          ...prevStatus,
          VIN:
            value.length === 17 &&
            (value.match(/\d/g) || []).length >= 9 &&
            (value.match(/\d/g) || []).length <= 12,
        }));
        break;
      case "Opis_usterki":
        setValidationStatus((prevStatus) => ({
          ...prevStatus,
          Opis_usterki: value.length >= 10,
        }));
        break;
      default:
        break;
    }
  };

  const allFieldsValid = Object.values(validationStatus).every(Boolean);

  //formspree
  const [state, handleSubmit] = useForm("mwkdwvvd");

  const [submitStatus, setSubmitStatus] = useState<
    "idle" | "submitting" | "sent"
  >("idle");

  useEffect(() => {
    if (state.submitting) {
      setSubmitStatus("submitting");
    } else if (state.succeeded) {
      setSubmitStatus("sent");
    }
  }, [state]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (submitStatus === "sent") {
      timerId = setTimeout(() => {
        onClose();
      }, 10000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [submitStatus, onClose]);

  //cleaning
  useEffect(() => {
    setSubmitStatus("idle");
    setFormValues({
      Email: "",
      Imie: "",
      Numer_telefonu: "",
      VIN: "",
      Opis_usterki: "",
    });

    setValidationStatus({
      Email: false,
      Imie: false,
      Numer_telefonu: false,
      VIN: false,
      Opis_usterki: false,
    });
  }, [isOpen]);

  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <ModalBackdrop>
      <ModalContent>
        <CloseButton onClick={onClose}>X</CloseButton>

        <ModalTitleContainer>
          <H1>
            {t("contactForm.title1")}
            <ColoredSpan>{t("contactForm.title2")}</ColoredSpan>
          </H1>
        </ModalTitleContainer>

        {submitStatus === "sent" ? (
          <SubmittedFormContainer>
            <H4>{t("contactForm.submittedText")}</H4>
          </SubmittedFormContainer>
        ) : (
          <StyledForm onSubmit={handleSubmit}>
            <InputGroup>
              <HalfWidthWrapper>
                <StyledInput
                  type="text"
                  name="Imie"
                  placeholder={t("contactForm.inputs.name")}
                  required
                  onChange={handleInputChange}
                  value={formValues.Imie}
                />
                <ValidationIcon
                  isValid={validationStatus.Imie}
                  src={validationStatus.Imie ? checkIcon : xIcon}
                />
              </HalfWidthWrapper>

              <HalfWidthWrapper>
                <StyledInput
                  type="tel"
                  name="Numer_telefonu"
                  placeholder={t("contactForm.inputs.phone")}
                  required
                  onChange={handleInputChange}
                  value={formValues.Numer_telefonu}
                />
                <ValidationIcon
                  isValid={validationStatus.Numer_telefonu}
                  src={validationStatus.Numer_telefonu ? checkIcon : xIcon}
                />
              </HalfWidthWrapper>
            </InputGroup>
            <InputWrapper>
              <StyledInput
                type="email"
                name="Email"
                placeholder={t("contactForm.inputs.email")}
                required
                onChange={handleInputChange}
                value={formValues.Email}
              />
              <ValidationIcon
                isValid={validationStatus.Email}
                src={validationStatus.Email ? checkIcon : xIcon}
              />
            </InputWrapper>

            <InputWrapper>
              <StyledInput
                type="text"
                name="VIN"
                placeholder={t("contactForm.inputs.vin")}
                required
                onChange={handleInputChange}
                value={formValues.VIN}
              />
              <ValidationIcon
                isValid={validationStatus.VIN}
                src={validationStatus.VIN ? checkIcon : xIcon}
              />
            </InputWrapper>
            <InputWrapper>
              <StyledTextarea
                name="Opis_usterki"
                placeholder={t("contactForm.inputs.description")}
                required
                onChange={handleInputChange}
                value={formValues.Opis_usterki}
              />
              {!allFieldsValid && (
                <ValidationIcon
                  textarea
                  isValid={validationStatus.Opis_usterki}
                  src={validationStatus.Opis_usterki ? checkIcon : xIcon}
                />
              )}
            </InputWrapper>

            <ButtonContainer>
              <AllFieldsAreRequiredContainer>
                <H6 style={{ color: "#d81b30" }}>
                  {t("contactForm.errorMessage")}
                </H6>
              </AllFieldsAreRequiredContainer>
              <Button type="submit" disabled={!allFieldsValid}>
                {submitStatus === "submitting"
                  ? t("contactForm.buttonSent")
                  : t("contactForm.buttonText")}
              </Button>
            </ButtonContainer>
          </StyledForm>
        )}
      </ModalContent>
    </ModalBackdrop>
  );
};

export default ContactFormModal;
